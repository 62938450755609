import React, { useState, useRef, useEffect, useContext } from 'react'
import eventContext from '../../../../contexts/EventContext'
import moment from 'moment'
import * as workerTimers from 'worker-timers'


const EventLogo = () => {

    const { eventData } = useContext(eventContext)

    const [countDownDays, setDays] = useState('00')
    const [countDownHours, setHours] = useState('00')
    const [countDownMinutes, setMinutes] = useState('00')
    const [countDownSeconds, setSeconds] = useState('00')

    let interval = useRef()

    let startTimestamp = `${eventData.start_date} ${eventData.start_time}`

    const startTimer = () => {
        workerTimers.setInterval(() => {
            const tillDate = moment.utc(startTimestamp).local().valueOf();
            let now = moment().valueOf();
            let duration = tillDate - now;
            let days = Math.floor(duration / (1000 * 60 * 60 * 24))
            let hours = Math.floor((duration % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            let minutes = Math.floor((duration % (1000 * 60 * 60) / (1000 * 60)))
            let seconds = Math.floor((duration % (1000 * 60) / 1000))

            if (duration < 0) {
                clearInterval(interval.current);
            } else {
                setDays(days);
                setHours(hours);
                setMinutes(minutes);
                setSeconds(seconds);
            }

        }, 1000)
    }

    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        }
    }, [])

    return (
        <>
            <div className="col-xl-5 col-md-12 mt-xl-0 mt-5 d-flex justify-content-xl-end justify-content-center p-0">
                <div className="info-card row d-flex justify-content-center align-items-center">
                    <div className="h-50 mt-5 d-flex border-info justify-content-center align-items-center">
                        {eventData.event_poster_url !== null ?
                            <img className="logo-img " src={eventData.event_poster_url} /> :
                            <img className="logo-img " src="/img/logo.svg" />
                        }
                    </div>
                    <div className="row h-25 w-100 mt-md-3 mb-md-0 p-2 p-sm-0 d-flex justify-content-around align-items-center">
                        <div className="col-sm-3 event-time-remainder p-0 mt-sm-3 mt-4 d-flex flex-column justify-content-center align-items-center">
                            <span>{countDownDays}</span>
                            <h5>DAYS</h5>
                        </div>
                        <div className="col-sm-3 event-time-remainder p-0 mt-sm-3 mt-4 d-flex flex-column justify-content-center align-items-center">
                            <span>{countDownHours}</span>
                            <h5>HRS</h5>
                        </div>
                        <div className="col-sm-3 event-time-remainder p-0 mt-sm-3 mt-4 d-flex flex-column justify-content-center align-items-center">
                            <span>{countDownMinutes}</span>
                            <h5>MIN</h5>
                        </div>
                        <div className="col-sm-3 event-time-remainder p-0 mt-sm-3 mt-4 d-flex flex-column justify-content-center align-items-center">
                            <span>{countDownSeconds}</span>
                            <h5>SEC</h5>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{`
        .info-card{
            display: flex;
            height: 100%;
            width: 97%;
            border-radius: 70px;
            /* box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.25); */
            z-index: 3;
        }
        .border-info{            
            background: #FFFFFF;
            max-height: 315px !important;
            min-height: 300px !important;
            box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.25);
            border-radius: 15px;
        }
        .logo-img{
            max-width: 102%;
            max-height: 98%;
            width: 102%;
            border-radius: 15px;
            object-fit: cover;
        }
        .event-time-remainder{
            height: 100px;
            width: 100px;
            border-radius: 30px;
            /* box-shadow: -7px -7px 22px 0px rgba(207, 207, 207, 0.6),
            7px 7px 22px 0px rgba(233, 232, 232, 0.4); */
            background: #FFFFFF;

        }
        .event-time-remainder span{
            font-family: Poppins;
            font-style: normal;
            font-weight: 800;
            font-size: 42px;
            text-align: center;
            text-transform: uppercase;
            color: #FB5850;
        }
        .event-time-remainder h5{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 15.4825px;
            line-height: 23px;
            text-align: center;
        }
        @media only screen and (max-width: 768px) {

            .info-card{
                height: 400px;
                width: 360px;
                border-radius: 15px;
            }
            .border-info{            
            background: #FFFFFF;
            max-height: 375px !important;
            min-height: 200px !important;
        }
            .event-time-remainder{
                height: 70px;
                width: 70px;    
                border-radius: 15px;           
            }
            .event-time-remainder span{
                font-size: 28px;
            }
          }
          @media only screen and (max-width: 576px) {
              .info-card{
                height: 400px;
                width: 360px;
              }
              .logo-img{
                width: 110%;
                height: 95%;
            }
            .event-time-remainder span{
                font-size: 25px;
                position: relative;
                top: 10px;
            }
            .event-time-remainder h5{
                font-size: 13px;
                position: relative;
            }

            .event-time-remainder{
                height: 50px;
                width: 50px;   
                border-radius: 10px;
            }
          }
            `}
            </style>
        </>
    )
}

export default EventLogo
