import React, { useContext } from 'react'
import eventContext from '../../../../contexts/EventContext'
import moment from 'moment';
import eventDateRange from '../../../../helpers/common'

const EventStats = () => {

    const { eventData } = useContext(eventContext)

    const date = {
        start_day: moment.utc(eventData.start_date).local().valueOf(),
        end_day: moment.utc(eventData.end_date).local().valueOf(),
    }

    const diff = date.end_day - date.start_day

    const duration = Math.floor(diff / (1000 * 60 * 60 * 24));

    return (
        <>
            <div className="row stats-container align-self-center justify-content-center d-flex align-items-end pt-sm-0 pt-4 px-3 px-sm-5">
                {eventData.expected_attendees !== 0 && (<div className="col-md-3 col-6 stats mb-md-5 mb-0 d-flex flex-column align-items-center justify-content-center">
                    <h5>Attendees</h5>
                    <span>{eventData.expected_attendees > 0 ? eventData.expected_attendees : 0}</span>
                </div>)}
                <div className="col-md-3 col-6 stats mb-md-5 d-flex flex-column align-items-center justify-content-center">
                    <h5>Days</h5>
                    <span>{duration}</span>
                </div>
                {/* commented out - eventData yet to be captured */}
                {/*<div className="col-md col-6 stats mb-md-5 mb-0 d-flex flex-column align-items-center justify-content-center">
                <h5>Workshops</h5>
                <span>4</span>
            </div>
            <div className="col-md col-6 stats mb-md-5 mb-0 d-flex flex-column align-items-center justify-content-center">
                <h5>Sessions</h5>
                <span>16</span>
            </div>*/}
                {eventData.expected_speakers !== 0 && (<div className="col-md-3 col-6 stats mb-md-5 mb-3 d-flex flex-column align-items-center justify-content-center">
                    <h5>Speakers</h5>
                    <span>{eventData.expected_speakers > 0 ? eventData.expected_speakers : 0}</span>
                </div>)}
                {/* <div className="col-md-3 col-6 stats mb-md-5 mb-3 d-flex flex-column align-items-center justify-content-center">
                    <h5>Good Times</h5>
                    <span>&infin;</span>
                </div> */}
            </div>
            <style jsx>{`
        .stats-container{
            height: 250px;
            width: 100%;
            background: #fff url("/img/stats-bg.svg") no-repeat;
            background-repeat: repeat-x;
            /* border-radius: 0 195px 0px 0; */
            /* clip-path: polygon(0 40%, 100% 0, 100% 100%, 0% 100%);   */
            z-index: 2; 
            position: absolute;
            bottom: -1px;
        }
        .stats span{
            font-family: Caveat Brush;
            font-style: normal;
            font-weight: normal;
            font-size: 80px;
            line-height: 130%;
        }
        @media only screen and (max-width: 1200px){
            .stats-container{
                clip-path: none;
                border-radius: 0px;
                height: 300px;
                margin-top: 20px;
            }

            .stats span{
                font-size: 70px;
            }
        }
        @media only screen and (max-width: 576px){
            .stats span{
                font-size: 50px;
            }
            .stats h5{
                font-size: 16px;
            }
        }
            `}
            </style>
        </>
    )
}

export default EventStats
