import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import speaker from '../../../../public/img/event_speaker_section/speaker.svg';
import { getSponsors } from "../../../crud/dedicatedEvent-crud";
import { errorToaster } from "../../../helpers/utils";
import ReadMore from "../../../components/ReadMoreText";
import Tippy from "@tippyjs/react";
import { sampleSponsorsData } from "../../../data/sampleEvent";

const EventSponsors = ({eventUrl, eventData, samplePage}) => {

    const [sponsors, setsponsors] = useState(null);


    const getAllsponsors = async () => {
        try {
            const res = await getSponsors(eventUrl);
            setsponsors(res.data);
        } catch (error) {
            errorToaster("Something went wrong while trying to get Sponsor, please try again later");
        }
    }

    useEffect(()=> {
        if(samplePage){
            setsponsors(sampleSponsorsData)
        }else{
        getAllsponsors();
    }
    }, [eventUrl])

    return (
        <div id="sponsors">
            <div className="sponsors_heading_description eventpage-fixed-padding">

                {/* sponsor title & description ---------------------*/}
                {
                    eventData?.sponsor_section_title ?
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className="event-heading">{eventData?.sponsor_section_title}</div>
                        </div>
                        :
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className="event-heading">Event</div>
                            <div className="speaker-heading-wrapper d-flex align-items-center">
                                <div className="speaker-heading">Sponsor(s)</div>
                                <img className="image_speaker" src={speaker} alt='Speaker' />
                            </div>
                        </div>
                }
                {
                    eventData?.sponsor_section_description &&
                        <div className="d-flex justify-content-center mt-4">
                        <div
                            dangerouslySetInnerHTML={{ __html: eventData.sponsor_section_description?.replace(/(?:\r\n|\r|\n)/g, '') || '' }}
                        />
                        </div>
                }

                {/* sponsors Non-Categories with sponsors ----------------*/}
                {
                    sponsors?.uncategorized?.length > 0 &&
                    <div className="">
                        {
                            sponsors?.uncategorized?.reduce((rows, key, index) => (index % 4 === 0 ? rows.push([key])
                            : rows[rows.length-1].push(key)) && rows, [])?.map((each, index)=> (
                                <>
                                    <div className="padding_down_category_name" />
                                    <div className="mt-2 mb-5 row sponsor_card_container">
                                        {
                                            each?.map((each, index)=>(
                                                <div className="sponsor_card_wrapper col-lg-3 col-sm-6 col-12 d-flex flex-column align-items-center">
                                                    <Tippy
                                                        content={each?.sponsor_name}
                                                        placement="top"
                                                        arrow={true}
                                                        className="custom-tippy"
                                                    >
                                                        <img className="sponsor_card" src={each?.sponsor_image_url} alt={each?.sponsor_name} onClick={()=>{
                                                            window.open(
                                                                each?.sponsor_url,
                                                                '_blank' // <- This is what makes it open in a new window.
                                                            );
                                                        }}  />
                                                    </Tippy>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            ))
                        }
                    </div>
                }

                {/* sponsors Categories with sponsors ----------------*/}
                {
                    sponsors?.categorized?.map((each, index)=>(
                        each?.sponsors?.length > 0 && (
                            <div className={`${index === 0 ? 'sponsor_category_root' : 'sponsor_category_root_1'}`}>
                                <div className="sponsor_category_name text-center">{each?.category_name}</div>
                                <div className="mt-4 mb-4 row sponsor_card_container">
                                    {
                                        each?.sponsors?.map((each, index)=> (
                                            <>
                                                <div className="sponsor_card_wrapper_2 col-lg-3 col-sm-6 col-12 d-flex flex-column align-items-center" >
                                                    <Tippy
                                                        content={each?.sponsor_name}
                                                        placement="top"
                                                        arrow={true}
                                                        className="custom-tippy"
                                                    >
                                                        <img className="sponsor_card" src={each?.sponsor_image_url} alt={each?.sponsor_name} onClick={()=>{
                                                            window.open(
                                                                each?.sponsor_url,
                                                                '_blank' // <- This is what makes it open in a new window.
                                                            );
                                                        }} />
                                                    </Tippy>
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    ))
                }


            </div>

            <style jsx global>
                {`
                    #speakers {
                        position: relative;
                    }
                    .sponsors_heading_description {
                        background-color: #fff;
                        // padding-top: 40px;
                        // padding-bottom: 110px;
                        // padding-left: 6rem;
                        // padding-right: 6rem;
                    }
                    .event-heading {
                        font-family: 'Prompt';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 64px;
                        line-height: 130%;
                        text-align: center;
                        color: #572148;
                    }
                    .speaker-heading-wrapper {
                        margin-left: 40px;
                    }
                    .speaker-heading {
                        font-family: 'Caveat Brush';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 64px;
                        line-height: 130%;
                        color: #FB5850;
                        margin-right: 10px;
                    }
                    .sponsor_category_name {
                        font-family: 'Prompt';
                        font-weight: 500;
                        font-size: 40px;
                        line-height: 120%;
                        color: #572148;
                    }
                    .padding_down_category_name {
                        padding: 20px;
                    }
                    .sponsor_card_container {
                        display: flex;
                        justify-content: center;
                    }
                    .sponsor_card_wrapper_2 {
                        margin-bottom: 50px;
                    }
                    .sponsor_card {
                        width: 263.8px;
                        height: 145.36px;
                        border-radius: 14.3478px;
                        border: 1.43478px solid #DDDDDD;
                        // background-position: center;
                        // background-repeat: no-repeat;
                        // background-size: cover;
                        cursor: pointer;
                    }
                    .sponsor_name_text {
                        color: #572148;
                    }
                    .sponsor_category_root {
                        margin-top: 30px;
                    }
                    .sponsor_category_root_1 {
                        margin-top: -25px;
                    }
                    @media only screen and (max-width: 1025px) {
                        .padding_down_category_name {
                            padding: 0px;
                        }
                        .sponsor_card_wrapper_2 {
                            margin-bottom: 20px;
                        }
                        .sponsor_card_wrapper {
                            margin-bottom: 5px;
                        }
                        .sponsor_card {
                            width: 213.8px;
                            height: 120.36px;
                        }
                        .sponsor_category_root_1 {
                            margin-top: 20px;
                        }
                    }
                    @media only screen and (max-width: 575px) {
                        .event-heading {
                          font-size: 42px;
                        }
                    }
                    @media only screen and (max-width: 480px) {
                        .sponsors_heading_description {
                            padding-left: 2rem;
                            padding-right: 2rem;
                        }
                        .speaker-heading {
                            font-size: 40px;
                            margin-right: 0px;
                        }
                        .speaker-heading-wrapper {
                            margin-left: 10px;
                        }
                        .image_speaker {
                            height: 85px;
                            width: 80px;
                        }
                    }
                `}
            </style>
        </div>
    )
}

export default EventSponsors;
