import React, { useContext } from "react";
import eventContext from "../../../contexts/EventContext";
import ReadMore from '../../../components/ReadMoreText'


const EventAbout = ({eventUrl, hideHighlight}) => {
    
    const { eventData } = useContext(eventContext);

    return (
        <>
            <div className="about-container pt-5 eventpage-fixed-padding" id="about">
                <img className="ticket-img" src="/img/event-about-ticket.svg" />
                <div className="row">
                    <div className="row about-header d-flex justify-content-start align-items-center mb-2">
                        <h1>About</h1>
                    </div>
                    <div className="row ms-1">
                        <div className="col-12 col-lg-6 col-xl-7 d-flex flex-column">
                            {eventData.description && <div className="desc my-5 pb-sm-4 pt-sm-0 pt-3">
                                <ReadMore
                                    content={eventData.description}
                                    maxChar={"auto"}                
                                    styles={{
                                        fontFamily: "Prompt",
                                        margin: "0px"
                                    }}
                                />
                            </div>}
                            {eventData.event_website ? (
                                <div className="about-link-btn align-items-center justify-content-around rounded py-3 px-3 mb-5">
                                    <a
                                        href={eventData.event_website}
                                        target="_blank"
                                        className="about-link-text text-white text-decoration-none"
                                    >
                                        Official Website
                                    </a>
                                    <i
                                        className="fa fa-external-link fa-lg text-white"
                                        aria-hidden="true"
                                    ></i>
                                </div>
                            ) : null}
                        </div>
                        <div style={{marginBottom: "4.6rem"}} className="col-12 col-xl-5 justify-content-center col-lg-6 d-flex position-relative pt-lg-5">
                            <div className="about-patch py-3 py-sm-2 px-sm-0 px-4 me-sm-0 pe-sm-5  mb-5 d-flex flex-column">
                                <div className="about-org pt-3 w-100 ps-sm-5 p-sm-3 d-flex flex-column align-items-start justify-content-around">
                                    <h5>About Organizer</h5>
                                    <div className="d-flex w-100">
                                        {
                                            eventData.organiser_info &&
                                            <span className="organizer-info">
                                                <ReadMore
                                                    content={eventData.organiser_info}
                                                    maxChar={"auto"}
                                                />
                                            </span>
                                        }

                                    </div>
                                    {/*commented out as eventData not being captured*/}
                                    {/*<a>{eventData.event_facebook_url}</a>
                                        <a>{eventData.event_twitter_url}</a>
                                        <a>{eventData.event_linkedin_url}</a>*/}
                                </div>
                                <div className="position-relative d-flex flex-column-reverse d-sm-block details-wrapper">
                                    <div className="about-logo p-3 d-flex flex-column justify-content-center align-items-center">
                                        {eventData.organiser_image_url !== "" ? (
                                            <img
                                                className="logo-short d-flex justify-content-center align-items-center"
                                                src={eventData.organiser_image_url}
                                            />
                                        ) : (
                                            <img
                                                className="logo-short d-flex justify-content-center align-items-center"
                                                src="/img/logo-short.svg"
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <div className="d-flex about-details flex-column justify-content-center mb-2">
                                            <div className="mb-2 about-item">
                                                <img
                                                    className=""
                                                    src="/img/about-email-logo.svg"
                                                />
                                                <a
                                                    href={`mailto:${eventData.organiser_email}`}
                                                    className="ms-3 ms-sm-4"
                                                >
                                                    {eventData.organiser_email}
                                                </a>
                                            </div>
                                            {eventData.organiser_phone && (
                                                <div className="mb-2 about-item">
                                                    <i className="fas fa-phone-alt about-org-icon"></i>
                                                    <a
                                                        href={`tel:${eventData.organiser_dial_code + eventData.organiser_phone}`}
                                                        className="ms-3 ms-sm-4"
                                                    >
                                                        {eventData.organiser_dial_code + eventData.organiser_phone}
                                                    </a>
                                                </div>
                                            )}
                                            {eventData.organiser_website && (
                                                <div className="mb-2 about-item">
                                                    <i className="fas fa-globe about-org-icon"></i>
                                                    <a
                                                        href={
                                                            eventData.organiser_website
                                                        }
                                                        target="_blank"
                                                        className="ms-3 ms-sm-4"
                                                    >
                                                        {eventData.organiser_website}
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                        {/* Social Links */}
                                        <div className="socials-div mb-3 w-75 d-flex flex-row">
                                            {eventData.organizer_facebook_url && (
                                                <a
                                                    href={
                                                        eventData.organizer_facebook_url !==
                                                            null
                                                            ? eventData.organizer_facebook_url
                                                            : `${`https://fb.com/${eventData.organiser_name}`}`
                                                    }
                                                    target="_blank"
                                                    className="w-25 share-item d-flex"
                                                >
                                                    <img
                                                        className="shout-share"
                                                        src="/img/u_facebook.svg"
                                                    />
                                                </a>
                                            )}
                                            {eventData.organizer_twitter_url && (
                                                <a
                                                    href={
                                                        eventData.organizer_twitter_url !==
                                                            null
                                                            ? eventData.organizer_twitter_url
                                                            : `${`https://twitter.com/${eventData.organiser_name}`}`
                                                    }
                                                    target="_blank"
                                                    className="w-25 share-item d-flex"
                                                >
                                                    <img
                                                        className="shout-share"
                                                        src="/home/twitter-footer.svg" 
                                                    />
                                                </a>
                                            )}
                                            {eventData.organizer_linkedin_url && (
                                                <a
                                                    href={
                                                        eventData.organizer_linkedin_url !==
                                                            null
                                                            ? eventData.organizer_linkedin_url
                                                            : `${`https://linkedin.com/${eventData.organiser_name}`}`
                                                    }
                                                    target="_blank"
                                                    className="w-25 share-item d-flex"
                                                >
                                                    <img
                                                        className="shout-share"
                                                        src="/img/u_linkedin.svg"
                                                    />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="about-patch-2" src="/img/about-patch-2.svg" />
            </div>
            <style jsx>
                {`
                .about-container{
                    height: auto;
                    background-color: #F6F4EF;  
                    position: relative;    
                    margin-top: ${!hideHighlight ? "0px" : "31px"};  
                    // padding-top: 5rem;        
                    // padding-bottom: 5rem;        
                }
                .ticket-img{
                    height: 450px;
                    position: absolute;
                    right: -150px;
                    top: -260px;
                    clip-path: polygon(0 51%, 100% 51%, 100% 100%, 0% 100%);
                }
                .about-header h1{
                    font-family: Prompt;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 64px;
                    line-height: 100%;
                    color: #572148;
                    z-index: 4;
                }
                .about-link-btn{
                    display:flex;
                    background-color : #F63C3D;
                    width: 250px;
                    z-index: 2;
                    cursor:pointer;
                    box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
                } 
                .about-link-btn:hover > *{
                    color: black !important;
                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }   
                .about-link-btn:active{
                    box-shadow: none;
                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
                .about-link-text{
                    font-family: Prompt;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: 0.04em;
                }
                .desc{
                    letter-spacing: 0.04em;
                    font-weight: 400; 
                    font-size: 18px;
                    z-index: 4;
                    word-wrap: break-word;
                }
                .about-patch{
                    position: sticky;
                    background: #F6F4EF url("/img/about-patch.svg") no-repeat;
                    background-size:cover;
                    width: 400px;
                    top: 20px;
                    height: fit-content;
                }
                .about-patch-2{
                    position: absolute;
                    bottom: 0px;
                    left: -160px;
                }
                .about-logo{
                    height: 160px;
                    width: 160px;
                    border-radius: 20px;
                    background-color: #fff;
                    box-shadow: -7px -7px 22px 0px rgba(207, 207, 207, 0.6),
                    7px 7px 22px 0px rgba(233, 232, 232, 0.4);
                    position:absolute;
                    left: -40px;
                    z-index:2;
                }
                .about-org h5{
                    font-family: Caveat Brush;
                    font-size: 28px;
                    line-height: 30px;
                    letter-spacing: 0.04em;
                    color: #FB5850;
                }
                .about-org a{
                    color: #572148;
                    font-family: Caveat Brush;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 18px;
                }
                .organizer-info{
                    font-size: 12px;
                    // display: -webkit-box;
                    // -webkit-line-clamp: 5;
                    // -webkit-box-orient: vertical;
                    overflow: hidden;
                    // text-overflow: ellipsis;
                }
                .about-details{
                    margin-left: 40%;
                }
                .about-details a{
                    color: #572148;
                    font-family: Caveat Brush;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 18px;
                }
                .logo-short{
                    width: 100%;
                    height: 100%;
                }
                .socials-div{
                    margin-left: 40%;
                }
                .shout-share:hover{
                    filter: none !important;
                }
                .shout-share{
                    transform: scale(1.15);
                }
                .share-item:hover{
                    transform: scale(1.05);
                }
                .about-org-icon{
                    font-size: 20px;
                    color:#572148;
                }
                .about-item {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .about-item:hover{
                    transform: scale(1.05);
                }
                .rm-typography{
                    margin: 0 !important;
                    padding: 0 !important;
                  }
                  .rm-text-container{
                    margin: 0 !important;
                    padding: 0 !important;
                  }
                  .rm-action-button-container *{
                    border: none !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    background:transparent;
                    text-decoration:underline;
                    font-size:medium;
                    margin-bottom:10px !important;
                    color: #fb5850;
                  }
                  .rm-action-button-container *:focus {
                    box-shadow: none !important;
                  }
                @media only screen and (max-width: 1380px){
                    .about-logo{
                        left: -20px;
                        height: 140px;
                        width: 140px;
                    }
                }
                @media only screen and (max-width: 992px){
                    .desc{
                        font-size: 16px;
                    }
                }
                @media only screen and (max-width: 575px) {

                }
                @media only screen and (max-width: 768px) {
                    .about-link-btn{
                        display:none;
                    }
                    .about-patch-2{
                        top: 0;
                        left: -80px;
                    }
                    .about-header h1{
                        font-size: 42px;
                    }
                    .ticket-img{
                        display:none;
                    }
                    .about-logo{
                        height: 140px;
                        width: 140px;
                    }       
                    .shout-share{
                        margin-left: 0%;
                    }        
                }
                @media only screen and (max-width: 576px) {
                    .about-patch {
                        width: 100%;
                    }
                    .about-logo {
                        left: 25%;
                        bottom: -100px;
                    }
                    .about-details {
                        margin-left: 0px;
                    }
                    .socials-div {
                        margin-left: 0px;
                    }
                    .details-wrapper{
                        padding-bottom: 2.2rem;
                    }
                    .about-org {
                        margin-bottom: .4rem;
                    }
                }
                `}
            </style>
        </>
    );
};

export default EventAbout;
