import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

const SideViewModal = ({ children, isOpen, setIsOpen, background = "#FFF", isMenu = false }) => {
    return (
        <>
            <AnimatePresence>
                {isOpen && (
                    <>
                        <motion.div
                            className='position-fixed d-flex justify-content-center align-items-center vh-90 top-0 side-view-modal-cont'
                            initial={{ x: -200 }}
                            animate={{
                                x: 10
                            }}
                            
                            transition={{ type: "easyInOut", bounce: 0, duration: 0.5 }}
                        >
                            {children}
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            transition={{ type: "spring", bounce: 0, duration: 0.1 }}
                            onClick={() => setIsOpen((isOpen) => !isOpen)}
                            style={{ background: "rgba(0, 0, 0, 0.75)", zIndex: "3001"}}
                            className="px-5 position-fixed vh-100 vw-100 d-flex align-items-center justify-content-center top-0 start-0 ani-height"
                        />
                    </>
                )}
            </AnimatePresence>
            <style>{`
                .side-view-modal-cont {
                    margin:120px;
                    display: flex;
                    background: ${background}; 
                    z-index: 3002; 
                    width : 770px;
                    height: auto;
                    min-width: 320px;
                }
                .ani-height{
                    height: 100% !important;
                }
                @media only screen and (min-width: 1145px){
                    .side-view-modal-cont {
                        margin:120px;
                        transform: translateX(0px) translateZ(0px) !important;
                        
                        z-index: 3002; 
                        
                    }

                }
                @media only screen and (max-width: 1343px){
                    .side-view-modal-cont {
                        margin:120px;
                        transform: translateX(0px) translateZ(0px) !important;
                        z-index: 3002; 
                    }

                }
                @media only screen (min-width: 768px) and (max-width: 1145px){
                    .side-view-modal-cont {
                        margin:120px;
                        transform: translateX(0px) translateZ(0px) !important;
                        z-index: 3002; 
                    }

                }


                @media only screen and (max-width: 768px) {
                    .side-view-modal-cont {
                        margin:120px;
                        transform: translateX(0px) translateZ(0px) !important;
                        width: 320px !important;
                        z-index: 3002; 
                        height: 630px !important;
                    }
                }
                @media only screen and (max-width: 647px){
                    .side-view-modal-cont {
                        margin-top:80px;
                        margin-bottom:80px;
                        margin-left:10px;
                        margin-right:10px;
                        transform: translateX(0px) translateZ(0px) !important;
                        width: 320px !important;
                        z-index: 3002; 
                        height: 630px !important;
                    }

                }
                @media only screen and (max-width: 600px){
                    .side-view-modal-cont {
                        margin-top:80px;
                        margin-bottom:80px;
                        margin-left:10px;
                        margin-right:10px;
                        transform: translateX(0px) translateZ(0px) !important;
                        width: 320px !important;
                        z-index: 3002; 
                        height: 630px !important;
                    }

                }
                @media only screen and (max-width: 480px) {
                    .side-view-modal-cont {
                        margin-top:80px;
                        margin-bottom:80px;
                        margin-left:10px;
                        margin-right:10px;
                        transform: translateX(0px) translateZ(0px) !important;
                        width: 320px !important;
                        z-index: 3002; 
                        height: 630px !important;
                    }

                }
                @media only screen and (max-width: 376px){
                    .side-view-modal-cont {
                        margin-top:80px;
                        margin-bottom:80px;
                        margin-left:10px;
                        margin-right:10px;
                        transform: translateX(0px) translateZ(0px) !important;
                        width: 320px !important;
                        z-index: 3002; 
                        height: 630px !important;
                    }

                }
                @media only screen and (max-width: 321px){
                    .side-view-modal-cont {
                        margin-top:80px;
                        margin-bottom:80px;
                        margin-left:10px;
                        margin-right:10px;
                        transform: translateX(0px) translateZ(0px) !important;
                        width: 300px !important;
                        z-index: 3002; 
                        height: 600px !important;
                    }

                }
            `}</style>
        </>
    )
}

export default SideViewModal
